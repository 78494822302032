import classNames from "classnames"
import _ from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import { capitalizeFirstLetter } from "../../lib/utils"
import contentStyles from "../../styles/content.module.styl"
import styles from "../../styles/outcomeTable.module.styl"
import OutcomeEffectBox from "./OutcomeEffectBox"
import RiskButton from "./RiskButton"
import RoundCaption from "../common/RoundCaption"

// TODO there is one problem with collapsible component
// long outcomes won't fit

const calcAbsEffect = (a, b) => a - b
const fewerMore = absEffect => (absEffect < 1 ? "fewer" : "more")

const risks = ["calculated", "low", "moderate", "high"]

const OutcomeTableRow = ({
  intervention,
  comparison,
  outcome,
  absDenominator,
}) => {
  const setCurrentRiskCb = useCallback(
    risk => e => {
      e.stopPropagation()
      setCurrentRisk(risk)
    },
    []
  )

  const risksToBeDisplayed = useMemo(() => {
    return _.reduce(
      risks,
      (acc, r) => {
        if (
          outcome.has(`${r}ControlAbsCount`) &&
          outcome.get(`${r}ControlAbsCount`) &&
          outcome.has(`${r}InterventionAbsCount`) &&
          outcome.get(`${r}InterventionAbsCount`)
        ) {
          acc[r] = {
            label:
              r === "calculated"
                ? "Study population"
                : capitalizeFirstLetter(r),
            [`${r}ControlAbsCount`]: outcome.get(`${r}ControlAbsCount`),
            [`${r}InterventionAbsCount`]: outcome.get(
              `${r}InterventionAbsCount`
            ),
          }
        }
        return acc
      },
      {}
    )
  }, [outcome])

  const [currentRisk, setCurrentRisk] = useState(
    _.first(Object.keys(risksToBeDisplayed))
  )

  const absEffect = useMemo(() => {
    const choose = outcome.get("choose")
    const checkedKey =
      choose === "comparison"
        ? `${currentRisk}ControlAbsCount`
        : `${currentRisk}InterventionAbsCount`
    const notCheckedKey =
      choose === "comparison"
        ? `${currentRisk}InterventionAbsCount`
        : `${currentRisk}ControlAbsCount`

    const absEffect = outcome.get("choose")
      ? calcAbsEffect(outcome.get(checkedKey), outcome.get(notCheckedKey))
      : 0

    return absEffect
  }, [outcome, currentRisk])

  return (
    <div className={styles.outcomeTableRow}>
      {/* <Collapsible calculateHeight={true}> */}
      <RoundCaption
        className={classNames(contentStyles.flexRow, contentStyles.alignCenter)}
      >
        <div className={styles.outcomeRow}>
          <h4 className={styles.outcomeName}>
            {outcome.get("name")}
            {Object.keys(risksToBeDisplayed).length > 1 && (
              <div>
                {_.map(risksToBeDisplayed, (r, key) => (
                  <RiskButton
                    active={currentRisk === key}
                    key={key}
                    label={r["label"]}
                    onClick={setCurrentRiskCb(key)}
                  />
                ))}
              </div>
            )}
          </h4>
          <div className={styles.effects}>
            <div className={styles.interventionEffect}>
              <OutcomeEffectBox
                absEffect={
                  outcome.get("choose") === "intervention"
                    ? Math.abs(absEffect)
                    : null
                }
                fewerMore={fewerMore(absEffect)}
                effectSize={outcome.get(`${currentRisk}InterventionAbsCount`)}
                absDenominator={absDenominator}
                type={
                  outcome.get("choose") === "intervention" ? "violet" : "white"
                }
              />
            </div>
            <div className={styles.comparisonEffect}>
              <OutcomeEffectBox
                childrenOrder={"right"}
                absEffect={
                  outcome.get("choose") === "comparison"
                    ? Math.abs(absEffect)
                    : null
                }
                fewerMore={fewerMore(absEffect)}
                effectSize={outcome.get(`${currentRisk}ControlAbsCount`)}
                absDenominator={absDenominator}
                type={outcome.get("choose") === "comparison" ? "blue" : "white"}
              />
            </div>
          </div>
          <div
            className={styles.outcomeCertainty}
            dangerouslySetInnerHTML={{
              __html: outcome.get("certainty")
                ? `${outcome.get("certainty")}<br>certainty`
                : "-",
            }}
          />
        </div>
      </RoundCaption>
      {/* <div className={styles.outcomeContent}>
          <OutcomeScale
            intervention={intervention}
            comparison={comparison}
            outcome={outcome}
            currentRisk={currentRisk}
            absDenominator={absDenominator}
            stepBy={absDenominator / 10}
            scaleTo={absDenominator}
          />
          {!_.isEmpty(outcome.get("whatHappens")) && (
            <div className={styles.whatHappens}>
              <HeaderContentBox
                title="What happens"
                content={outcome.get("whatHappens")}
              />
            </div>
          )}
        </div> */}
      {/* </Collapsible> */}
    </div>
  )
}

export default OutcomeTableRow
