import Immutable from "immutable"
import React from "react"
import HeaderWithIcon from "../common/HeaderWithIcon"
import TextContent from "../common/TextContent"
import OutcomesTable from "../outcomes/OutcomesTable"
import styles from "../../styles/content.module.styl"

const ResearchResults = ({ researchResults }) => {
  return (
    <div>
      <HeaderWithIcon title="Research results" type="research" />
      <TextContent content={researchResults.content} />
      <h2 className={styles.mt40}>
        {researchResults.outcomesComparison.title}
      </h2>
      <div>{researchResults.outcomesComparison.subTitle}</div>
      <OutcomesTable
        happensTo={researchResults.outcomesComparison.happensTo}
        intervention={researchResults.outcomesComparison.intervention}
        comparison={researchResults.outcomesComparison.comparison}
        absDenominator={researchResults.outcomesComparison.absDenominator}
        outcomes={Immutable.fromJS(researchResults.outcomesComparison.outcomes)}
        explanations={researchResults.outcomesComparison.explanations}
      />
    </div>
  )
}

ResearchResults.propTypes = {}

export default ResearchResults
