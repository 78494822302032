import React from "react"
import _ from "lodash"
import BenefitsAndHarms from "../components/comparison/BenefitsAndHarms"
import SubPageHeader from "../components/header/SubPageHeader"
import Layout from "../components/layout"
import ArrowNavigation from "../components/navigation/ArrowNavigation"
import ResearchResults from "../components/outcomes/ResearchResults"
import styles from "../styles/content.module.styl"
import textStyles from "../styles/texts.module.styl"

const Options = ({ location, data, pathContext }) => {
  const nodeData = data.decisionAidsJson
  const { pageId, groupId, subPage, group } = pathContext

  const prevLink = groupId ? `/${pageId}/${groupId}/facts` : `/${pageId}/facts`
  const nextLink = groupId
    ? `/${pageId}/${groupId}/summary`
    : `/${pageId}/summary`

  const benefits = group
    ? group.benefitsAndHarms.benefits
    : nodeData.benefitsAndHarms.benefits

  const harms = group
    ? group.benefitsAndHarms.harms
    : nodeData.benefitsAndHarms.harms

  const benefitsAndHarmsTitle = group
    ? group.benefitsAndHarms.title
    : !_.isEmpty(nodeData.benefitsAndHarms.title)
    ? nodeData.benefitsAndHarms.title
    : nodeData.researchResults.outcomesComparison.intervention

  return (
    <Layout pageId={pageId} withGlossaryButton>
      <SubPageHeader
        title={nodeData.subPageTitle}
        group={group}
        hasGroups={!_.isEmpty(nodeData.groups)}
        location={location}
        logo={nodeData.logo}
        pageId={pageId}
        groupId={groupId}
        subPage={subPage}
      />
      <div className={styles.content}>
        <ResearchResults researchResults={nodeData.researchResults} />
        <h2 className={textStyles.comparisonHeader}>Comparison</h2>

        <BenefitsAndHarms
          title={benefitsAndHarmsTitle}
          benefits={benefits}
          harms={harms}
        />

        <ArrowNavigation prevLink={prevLink} nextLink={nextLink} />
      </div>
    </Layout>
  )
}

export default Options

export const query = graphql`
  query OptionsQuery($pageId: String) {
    decisionAidsJson(pageId: { eq: $pageId }) {
      title
      subPageTitle
      logo
      benefitsAndHarms {
        title
        benefits
        harms
      }
      researchResults {
        content
        outcomesComparison {
          title
          subTitle
          intervention
          comparison
          absDenominator
          happensTo
          outcomes {
            name
            calculatedInterventionAbsCount
            calculatedControlAbsCount
            choose
            certainty
            whatHappens
          }
        }
      }
    }
  }
`
