import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import styles from "../../styles/outcomeTable.module.styl"

const RiskButton = ({ label, active, onClick }) => {
  return (
    <button
      className={classNames(styles.riskButton, {
        [styles.active]: active,
      })}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

RiskButton.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default RiskButton
